.gallery-item {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  
  .gallery-item img {
    max-width: 160px !important; /* Ensure images in the grid gallery have a maximum width of 160px */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Change cursor to pointer when hovering over images */
  }
  

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-body img {
    max-width: 350px !important; /* Ensure images in the modal have a maximum width of 350px */
    height: auto; /* Maintain aspect ratio */
    margin: auto;
  }
  .modal-header .modal-title{
    text-align: center !important;
    margin: auto;
  }

  .modal-footer .btn{
    
    color: white;
    background-color: var(--red-color);
    border-color: var(--red-color);
  }


  @media only screen and (max-width: 600px) {
    .gallery-item img {
        max-width: 140px !important;
      }

      .modal-body img {
        max-width: 300px !important; /* Ensure images in the modal have a maximum width of 350px */
      }
  }