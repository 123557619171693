.navbar {
    background-color:var(--red-color) !important;
  }

  .navbar-nav .nav-link {
    font-weight: 600px !important;
    color: white !important;
    font-weight: 600px !important;
  }

  .navbar-nav .nav-link .btn {
    font-weight: 600 !important;
  }

  .navbar-toggler-icon {
    background-image: url("../assets/images/button.png");
  }
  
  .navbar-toggler {
    border-color: var(--red-color);
  }
  
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0;
  }

  .dropdown-menu {
    --bs-dropdown-link-active-bg: var(--red-color);
  }
  @media only screen and (max-width: 600px) {
    .container {
        padding: 0px 20px;
    }
    .navbar-nav .nav-link .btn {
        margin: 0px 5px 0px 0px;
    }
  }

  #headerIcon i{
    font-size: 1.2rem;
    color: white;
    margin: 0px 5px;
  }