#aboutUs img{
    margin: 15px 0px;
}

#aboutUs h5{
font-weight: 400;
font-size: 2.2rem !important;
}

@media only screen and (max-width: 600px) {
    #aboutUs h5{
        font-size: 1.5rem !important;
        }
    }

#faculties Col{
 display: flex;
 justify-content: center;
 align-items: center;
}