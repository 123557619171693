:root {
  --red-color: #b70031;
  --green-color:#113e00;
  --light-green-color:#2f6f17;
  --yellow-color:#fffb00;
  --light-yellow-color:#fffce4;
  --light-dark-yellow-color:#fff5a5;
  --font-family: Arial, sans-serif;
  --max-width-container : 1100px;
}


.container {
  max-width: var(--max-width-container) !important;
}