@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 10px 0px;
  white-space: nowrap;
  position: relative;
}

.logos:before, .logos:after {
  position: absolute;
  top: 0;
  content: '';
  width: 250px;
  height: 100%;
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255,255,255,0), rgb(255, 255, 255));
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255,255,255,0), rgb(255, 255, 255));
}

.logo_items {
  display: inline-block;
  animation: 35s slides infinite linear;
}

.logos:hover .logo_items {
  animation-play-state: paused;
}

.logo_items img{
  height: 100px;
  margin: 0px 5px;
}


@media (max-width: 768px) {
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255, 0.4));
  }
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255, 0.4));}
} 