.nav-tabs {
    background-color: var(--green-color);
    --bs-nav-tabs-border-radius: 0px !important;
    --bs-nav-tabs-link-active-bg: #DCE775;
    --bs-nav-tabs-link-active-color: var(--green-color);
    font-weight: 400 !important;
}

.nav-link {
    color: white;
}

.nav-item:active {
    font-weight: 500 !important;
}

.nav {
    --bs-nav-link-hover-color: var(--yellow-color);
}

.img-fluid {
    max-width: 60% !important;
}

.headingTitleNormal {
    font-family: Open Sans, sans-serif;
    font-weight: 700;
    text-align: center;
    letter-spacing: -1.5px;
    background: -webkit-linear-gradient(270deg, #33691E, #113e00, #689F38, #AED581);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.headingTitleGradient {
    background: -webkit-linear-gradient(90deg, #b70031, #b70031, tomato, tomato);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 600px) {
    .nav-tabs {
        display: flex;
        justify-content: normal !important;
        flex-wrap: nowrap;
        overflow-x: auto; /* Use overflow-x for horizontal scrolling */
        overflow-y: hidden; /* Hide vertical scrollbar */
    }

    .nav-tabs::-webkit-scrollbar {
        display: none; /* Hide scrollbar in WebKit browsers */
    }

    .nav-tabs {
        -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
        scrollbar-width: none; /* Hide scrollbar in Firefox */
    }

    .img-fluid {
        max-width: 90% !important;
    }

    /* Add the hover effect for .container */
    .container:hover .image {
        transform: scale(1.05); /* Increase size by 5% on hover */
    }

    .container:hover .overlay {
        opacity: 1;
    }
}


.video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    padding-top: 56.25%;
    margin-bottom: 30px;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }